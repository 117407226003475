<template>
	<v-footer dark padless>
		<v-card class="flex" flat tile>
			<v-card-title class="primary">
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<strong class="subheading"
							>Say hi on social media!</strong
						>
					</v-col>

					<v-col cols="12" class="text-center">
						<v-btn
							v-for="icon in icons"
							:key="icon"
							class="mx-4"
							dark
							icon
						>
							<v-icon size="24px">
								{{ icon }}
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>

			<v-card-text class="py-2 white--text secondary text-center">
				&copy;
				{{ new Date().getFullYear() }} —
				<strong>Bad Hive Illustrations</strong>
				<v-divider vertical class="ml-2 mr-1 py-2"></v-divider>
				<router-link style="text-decoration: none" to="privacy">
					Privacy
				</router-link>
				<v-divider vertical class="mx-1 py-2"></v-divider>
				<router-link style="text-decoration: none" to="imprint">
					Imprint
				</router-link>
			</v-card-text>
		</v-card>
	</v-footer>
</template>

<script>
	export default {
		data: () => ({
			icons: [
				"mdi-instagram",
				"mdi-twitter",
				"mdi-youtube",
				"mdi-reddit",
				"mdi-google-plus",
			],
		}),
	};
</script>

<style scoped>
	.v-divider {
		background-color: rgba(255, 255, 255, 0.6);
	}
</style>
