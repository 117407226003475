import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#ffd81a",
				secondary: "#000105",
				tertiary: "#383838",
			},
		},
	},
});

export default vuetify;
