<template>
	<v-navigation-drawer
		floating
		permanent
		elevation="2"
		style="position: fixed; top: 64px; max-width: 16.6666666667%;"
	>
		<router-link to="/">
			<v-img
				:src="require('@/assets/badhivelogo.jpg')"
				contain
				max-height="120px"
			>
			</v-img>
		</router-link>

		<v-list nav flat dense>
			<div v-for="(item, i) in items" :key="i">
				<v-list-item
					v-if="!item.children"
					:to="item.to"
					active-class="secondary white--text"
				>
					<!-- <v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon> -->

					<v-list-item-title v-text="item.text" />
				</v-list-item>

				<v-list-group
					v-else
					:key="item.text"
					:to="item.to"
					:value="true"
					color="black"
				>
					<template v-slot:activator>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</template>

					<v-list-item
						v-for="child in item.children"
						:to="child.to"
						:key="child.text"
						active-class="secondary white--text"
					>
						<v-list-item-icon>
							<v-icon>{{ child.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ child.text }}</v-list-item-title>
					</v-list-item>
				</v-list-group>
			</div>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "NavDrawer",
		props: {
			items: {
				type: Array,
				required: true,
			},
		},
		data: () => ({
			selectedItem: null,
		}),
	};
</script>

<style scoped>
	/* .v-list .v-list-item--active {
		color: #ffd81a !important;
	} */
</style>
