<template>
	<v-app>
		<v-app-bar
			v-if="isTouch"
			app
			color="black"
			elevation="0"
			class="text-center"
		>
			<v-container height="100%" align="center">
				<v-row align="center">
					<router-link to="/">
						<v-img
							:src="require('@/assets/badhivelogo.jpg')"
							contain
							max-height="44px"
							max-width="44px"
							class="ml-2"
						>
						</v-img>
					</router-link>
					<v-spacer></v-spacer>
					<v-col>
						<h3 class="white--text text-no-wrap ml-2">
							Bad Hive Illustrations
						</h3>
					</v-col>
					<v-spacer></v-spacer>
					<v-app-bar-nav-icon
						@click="openDrawer()"
						class="white--text my-auto"
						x-large
					>
					</v-app-bar-nav-icon>
				</v-row>
			</v-container>
		</v-app-bar>

		<v-app-bar v-else app color="black" elevation="0" class="text-center">
			<v-container height="100%" align="center">
				<v-row align="center">
					<v-spacer></v-spacer>
					<v-col>
						<span
							class="white--text text-no-wrap ml-2"
							style="
								font-size: 1.6em !important;
								font-weight: 700 !important;
							"
						>
							Bad Hive Illustrations
						</span>
					</v-col>
					<v-spacer></v-spacer>
				</v-row>
			</v-container>
		</v-app-bar>

		<MobileNavDrawer v-if="isTouch" :items="items" />

		<v-main>
			<v-container
				fluid
				style="height: 100%; align-items: start !important"
			>
				<v-row
					v-if="!isTouch"
					style="height: 100%"
					align="stretch"
					dense
				>
					<v-col cols="2">
						<NavDrawer :items="items" />
					</v-col>
					<v-col cols="8">
						<router-view />
					</v-col>
				</v-row>

				<v-row v-else style="height: 100%" align="stretch">
					<v-col cols="12">
						<router-view />
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<Footer />
	</v-app>
</template>

<script>
	import NavDrawer from "./components/NavDrawer.vue";
	import Footer from "./components/Footer.vue";
	import MobileNavDrawer from "./components/MobileNavDrawer.vue";

	export default {
		name: "App",

		components: {
			NavDrawer,
			Footer,
			MobileNavDrawer,
		},

		data: () => ({
			drawer: false,
			selectedItem: 1,
			items: [
				{
					text: "Home",
					icon: "mdi-home",
					to: "/",
				},
				{ text: "About Me", icon: "mdi-account", to: "about" },
				{
					text: "My Portfolio",
					icon: "mdi-image",
					to: "portfolio",
					children: [
						{ text: "Overview", to: "portfolio" },

						{ text: "Illustrations", to: "illustrations" },
						{ text: "Merch", to: "merch" },
					],
				},
				{
					text: "References",
					icon: "mdi-file-document",
					to: "references",
				},
				{ text: "Contact Me", icon: "mdi-email", to: "contact" },
			],
		}),

		computed: {
			isTouch() {
				return window.matchMedia("(pointer: coarse)").matches;
			},
		},

		methods: {
			openDrawer() {
				// emit event to open drawer from child component
				this.$root.$emit("OpenDrawer");
				// console.log("open drawer");
			},
		},
	};
</script>

<style>
	@import "../resources/css/app.css";

	:deep(.v-toolbar__content) {
		padding: 0px !important;
	}
</style>
