import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: () =>
			import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "About" */ "../views/About.vue"),
	},
	{
		path: "/portfolio",
		name: "portfolio",
		component: () =>
			import(
				/* webpackChunkName: "Portfolio" */ "../views/Portfolio.vue"
			),
	},
	{
		path: "/customs",
		name: "customs",
		component: () =>
			import(/* webpackChunkName: "Customs" */ "../views/Customs.vue"),
	},
	{
		path: "/illustrations",
		name: "illustrations",
		component: () =>
			import(
				/* webpackChunkName: "Illustrations" */ "../views/Illustrations.vue"
			),
	},
	{
		path: "/merch",
		name: "merch",
		component: () =>
			import(/* webpackChunkName: "Merch" */ "../views/Merch.vue"),
	},
	{
		path: "/references",
		name: "references",
		component: () =>
			import(
				/* webpackChunkName: "References" */ "../views/References.vue"
			),
	},
	{
		path: "/contact",
		name: "contact",
		component: () =>
			import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
	},
	{
		path: "/imprint",
		name: "imprint",
		component: () =>
			import(/* webpackChunkName: "Imprint" */ "../views/Imprint.vue"),
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () =>
			import(/* webpackChunkName: "Privacy" */ "../views/Privacy.vue"),
	},
	{
		path: "/singlepicture",
		name: "singlePicture",
		component: () =>
			import(
				/* webpackChunkName: "SinglePicture" */ "../views/SinglePicture.vue"
			),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
